import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { StudioContextProvider } from 'contexts/StudioContext';
import { Fallback } from 'router/Fallback';
import { RequireAuth } from 'router/RequireAuth';

const StudioHome = lazy(() => import('pages/studio/StudioHome'));
const StudioPremium = lazy(() => import('pages/studio/StudioPremium'));
const CreatedSetOverview = lazy(() => import('pages/studio/CreatedSetOverview'));
const CreatedSetDetail = lazy(() => import('pages/studio/CreatedSetDetail'));
const StudioCreate = lazy(() => import('pages/studio/StudioCreate'));
const TovertafelOverview = lazy(() => import('pages/studio/TovertafelOverview'));
const TovertafelDetail = lazy(() => import('pages/studio/TovertafelDetail'));

const GalleryOverview = lazy(() => import('pages/studio/GalleryOverview'));
const GalleryDetail = lazy(() => import('pages/studio/GalleryDetail'));

export const StudioRoutes = [
  {
    path: 'studio',
    element: (
      <Suspense fallback={<Fallback />}>
        <StudioContextProvider>
          <Outlet />
        </StudioContextProvider>
      </Suspense>
    ),
    children: [
      {
        path: 'gallery',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <GalleryOverview />
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="studio.gallery.view">
                  <GalleryDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'lab',
    element: (
      <Suspense fallback={<Fallback />}>
        <StudioContextProvider>
          <Outlet />
        </StudioContextProvider>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <StudioHome />
          </Suspense>
        ),
      },
      {
        path: 'create',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="studio.set.create">
              <StudioCreate />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'sets/tover',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="view.tover.content-sets">
              <StudioPremium />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'creations/:id',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="studio.set">
              <CreatedSetDetail />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'creations',
        element: (
          <Suspense fallback={<Fallback />}>
            <CreatedSetOverview />
          </Suspense>
        ),
      },
      {
        path: 'library',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <GalleryOverview />
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="studio.gallery.view">
                  <GalleryDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'units',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="studio.unit">
                  <TovertafelOverview />
                </RequireAuth>
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="studio.unit">
                  <TovertafelDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];
